import { Injectable } from '@angular/core';
import {
  PaymentChargeRequestType,
  PaymentRefIdRequestType,
} from 'common-types';
import { PaymentApiService } from './payment-api.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(private paymentApiService: PaymentApiService) {}

  public getRefId() {
    console.log('GetRefId?');
    const requestData: PaymentRefIdRequestType = {
      fname: 'John',
      lname: 'Smith',
      cardNumber: '4000000000000002',
      ccv: '555',
      expirationDate: '1127',
      useDemoMode: false,
      avsAddress: '',
      avsZip: '',
      verify: 'N',
      addToken: 'N',
    };

    var tax = 0;

    this.paymentApiService.apiGetRefId(requestData).then((res) => {
      console.log('Response');
      console.log(res);
    });
  }

  public createCharge() {
    console.log('Create Charge?');
    const requestData: PaymentChargeRequestType = {
      invoiceNumber: 'MyInvoiceNumber1',
      customerCode: 'MyCustomerCode',
      fname: 'John',
      lname: 'Doe',
      amount: 12.2,
      useDemoMode: false,
      referenceId: '4692536310000002',
    };
    // const requestData: PaymentChargeRequestType = {
    //   invoiceNumber: 'MyInvoiceNumber1',
    //   customerCode: 'MyCustomerCode',
    //   fname: 'John',
    //   lname: 'Doe',
    //   cardNumber: '4000000000000002',
    //   ccv: '777',
    //   expirationDate: '1126',
    //   amount: 12.2,
    //   useDemoMode: false,
    // };

    var tax = 0;

    this.paymentApiService.apiCreateCharge(requestData).then((res) => {
      console.log('Response');
      console.log(res);
    });
  }
}
